import React, { Component } from "react"
import { Link } from 'gatsby';
// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from "../components/layout"
import SEO from "../components/seo"

import SimpleModal from '../components/utilities/SimpleModal';

import "./casestudycitrixgoogledaas.scss"

import imgQuote from "../images/quotation_mark.webp"

class VideoLauncher extends Component {

    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
        this.openModal = this.openModal.bind(this);
    };
    openModal() {
        this.modalRef.current.handleOpen();
    };


    render() {
        return (
            <div
                onClick={() => this.openModal()} 
                id="videoLauncer"
                className="videoLauncherBtn"
                >
                <img
                    src="https://www.meritmile.com/uploads/2022/08/cccs-video.jpg"
                    className="results-image img-fluid"
                    alt="Citrix Google Contact Center Marketing Case Study Challenge"
                    width="616px"
                    style={{ marginTop: '60px'}}
                />
                <SimpleModal videoSRC='62SwpbK8h_E' innerRef={this.modalRef} />
            </div>
        );
    }
}

export default ({ pageContext }) => (
    <Layout>
        <SEO
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image}
            link={pageContext.link}
        />
        <div className="case-study-cgdaas-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div
                        className="header-container"
                        style={{
                            backgroundImage: `url(${pageContext.acf.header_image.csOneHedImaLocalFile.childImageSharp.fluid.src})`,
                        }}
                    >
                        <img src={imgQuote} alt="" />
                        <h2>{pageContext.acf.header_text}</h2>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-md-8">
                        <img
                            src={
                                pageContext.acf.client_logo.csOneCliLogLocalFile.childImageSharp
                                    .fluid.src
                            }
                            className="client-logo"
                            width={pageContext.acf.client_logo.media_details.width / 1.5}
                            height={pageContext.acf.client_logo.media_details.height / 1.5}
                            alt={pageContext.yoast.opengraph_title}
                        />
                    </div>
                    <div className="col-md-4">
                        <a href="https://www.meritmile.com/uploads/2022/10/chrome-and-citrix-vertical-marketing-case-study.pdf" className="btn downloadBtn" target="_blank" rel="nofollow noopener noreferrer" style={{ marginTop: '4em'}}>Download the PDF here</a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="bar"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div
                            className="first-paragraph"
                            dangerouslySetInnerHTML={{
                                __html: pageContext.acf.first_paragraph,
                            }}
                        />
                        {/* <a href="https://www.meritmile.com/uploads/2019/03/quackSkins-and-merit-mile-case-study.pdf" className="btn sitebtn" target="_blank" rel="nofollow noopener noreferrer">Read the Full Case Study</a> */}
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">            
                <div className="row margin-b">
                    <div className="col-md-6">
                        <div
                            className="second-paragraph"
                            dangerouslySetInnerHTML={{
                                __html: pageContext.acf.second_paragraph,
                            }}
                        />
                    </div>
                    <div className="col-md-6">
                        <VideoLauncher />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <img
                            src="https://www.meritmile.com/uploads/2022/08/cccs-ebook.jpg"
                            className="solution-image img-fluid"
                            alt="Citrix Google Contact Center Marketing Case Study Solution"
                            width="616px"
                        />
                    </div>
                    <div className="col-md-6">
                        <div
                            className="third-paragraph"
                            dangerouslySetInnerHTML={{
                                __html: pageContext.acf.third_paragraph,
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper margin-b">
                <div className="row">
                    <div className="col-12">
                        <h3>Results</h3>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-md-6">
                        <p>Vertical marketing content does't work in isolation. Each piece of marketing content was part of a larger campaign execution strategy across paid content syndication, nurture, social media, and organic search. Display ads, <a href="https://www.citrix.com/products/citrix-daas/resources/daas-and-chrome-contact-center.html" target="_blank" rel="noopener noreferer">landing pages</a>, social posts, blogs, and earned media worked in dovetail to reach contact center buyers.</p>
                        <p>The Merit Mile PR team ran a series of industry outreach programs for additional awareness to contact center and healthcare publications and websites, landing Citrix multiple bylined articles. Key placements included <a href="https://www.channelpronetwork.com/article/secure-daas-solutions-help-remote-contact-center-agents?page=0%2C0" target="_blank" rel="noopener">ChannelPro</a>, Contact Center Pipeline, and <a href="https://www.healthcarebusinesstoday.com/how-is-secure-desktop-as-a-service-daas-reshaping-the-way-healthcare-organizations-operate/" target="_blank" rel="noopener">Healthcare Business Today</a>, in addition to securing a major analyst briefing session with Gartner.</p>
                    </div>
                    <div className="col-md-6">
                        <img
                            src="https://www.meritmile.com/uploads/2022/08/webs.jpg"
                            className="results-image img-fluid"
                            alt="Citrix Google ChromeOS DaaS PR Case Study Results"
                            width="616px"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <p>Finally, Merit Mile ensured that sellers and channel partners were equipped to lead the downstream sales conversations. Sales enablement efforts included contact center specific tele-scripts, reference cards, and nurture emails that ensured sellers were talking the talk and properly engaging with prospects.</p>
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
            <div className="container wider detail-wrapper">
                <div className="row four-samples margin-b">
                    <div className="col-md-9">
                        <img
                            src="https://www.meritmile.com/uploads/2022/08/cccs-infographic.jpg"
                            className="quote-image img-fluid"
                            alt="Sample of Partner Branded Deliverable 2"
                        />
                    </div>
                    <div className="col-md-3">
                        <img
                            src="https://www.meritmile.com/uploads/2022/08/banners.jpg"
                            className="quote-image img-fluid"
                            alt="Sample of Partner Branded Deliverable 2"
                        />
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row fifth-paragraph margin-b">
                    <div className="col-md-6">
                        <div className="">
                            <h3><b style={{ fontWeight: '400', fontStyle: 'italic' }}>Merit Mile Services</b></h3>
                            <ul>
                                <li>
                                    Marketing strategy
                                </li>
                                <li>
                                    Project management
                                </li>
                                <li>
                                    Copywriting
                                </li>
                                <li>
                                    Creative design
                                </li>
                                <li>
                                    Video production
                                </li>
                                <li>
                                    Earned media and PR
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="">
                        <h3><b style={{ fontWeight: '400', fontStyle: 'italic' }}>Deliverables</b></h3>
                            <ul>
                                <li>
                                    Videos
                                </li>
                                <li>
                                    Solution briefs
                                </li>
                                <li>
                                    Presentations
                                </li>
                                <li>
                                    Sales tools
                                </li>
                                <li>
                                    Display ads
                                </li>
                                <li>
                                    Social media and blogs
                                </li>
                                <li>
                                    Bylined articles
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>         
            <div className="fullWidthGrayCTA">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h5>Learn more about how Merit Mile can support your vertical content marketing, lead generation, and sales goals by contacting <a href="mailto:Hello@MeritMile.com?subject=Citrix%20%20Google%20ChromeOS20DaaS%20Case%20Study%20Inquiry"><b style={{ color: "black" }}>hello@meritmile.com.</b></a></h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="yourteam-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3>Your Merit Mile Team</h3>
                        </div>
                    </div>
                    <div className="row margin-b">
                        <div className="col-12">
                            <div className="team-cards">
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/sl-thumb.jpg"
                                        alt="Scott Lindars"
                                        className="img-fluid"
                                    />
                                    <h4>Scott Lindars</h4>
                                    <p>
                                        Director<br />
                                        Marketing +<br />
                                        Business Strategy
                                    </p>
                                </div>
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/jh-thumb.jpg"
                                        alt="Janice Hughes"
                                        className="img-fluid"
                                    />
                                    <h4>Janice Hughes</h4>
                                    <p>
                                        Technology<br />
                                        Copywriter<br />
                                        &nbsp;
                                    </p>
                                </div>
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/sd-thumb.jpg"
                                        alt="Saul Darville"
                                        className="img-fluid"
                                    />
                                    <h4>Saul Darville</h4>
                                    <p>
                                        Executive<br />
                                        Creative<br />
                                        Director
                                    </p>
                                </div>
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/mc-thumb.jpg"
                                        alt="Mitch Curry"
                                        className="img-fluid"
                                    />
                                    <h4>Mitch Curry</h4>
                                    <p>
                                        Project<br />
                                        Manager<br />
                                        &nbsp;
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fullWidthImageWrapper">
                <img
                    src="https://www.meritmile.com/uploads/2021/09/mm-divider.jpg"
                    alt="Merit Mile Icon Divider"
                    className="img-fluid"
                />
            </div>
            <div className="about-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h2>About Merit Mile</h2>
                        </div>
                    </div>
                    <div className="row margin-b">
                        <div className="col-md-6">
                            <p>Established in 2007 in Boca Raton, FL, Merit Mile is a marketing services provider focused on helping companies and corporate marketing departments build brands, drive sales revenue, and garner publicity in their respective industries. Our unique differentiator at Merit Mile is our ability to 100% own the message, the publicity, and the demand generation aspects of the corporate marketing function. Clients hire us and rehire us for our ability to refine their messaging/value proposition, develop top and mid-funnel leads, and get the type of editorial publicity that differentiates them from their competitors. From branding to PR to demand generation, Merit Mile is the high-octane fuel that invigorates corporate growth engines. To learn more, please email us at Hello@MeritMile.com, call us at 1-561-362-888, or visit us at www.MeritMile.com. </p>
                        </div>
                        <div className="col-md-6"><img className="img-fluid" src="https://www.meritmile.com/uploads/2021/06/mm-headquater.jpg" alt="Merit Mile Headquarter in Boca Raton, FL" /></div>
                    </div>
                </div>
            </div>
            <div className="container quote-wrapper">
                <div className="row">
                    <div className="col-12">
                        <p>
                            For more insights and to connect<br />
                            with Merit Mile <a href="mailto:Hello@MeritMile.com?subject=Citrix%20%20Google%20ChromeOS%20DaaS%20Case%20Study%20Inquiry"><b style={{ color: "black" }}>email us here</b>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)
